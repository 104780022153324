import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { HomePage, LoginPage, BrewPage } from '../pages';
import AuthRoute from './AuthRoute';

const Routes = () => (
  <Router>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/login" component={LoginPage} />
    <AuthRoute exact path="/brew" component={BrewPage} />
  </Router>
);

export default Routes;
