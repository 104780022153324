import { keyframes } from 'styled-components/macro';

export const fillup = keyframes`
  0%,
  20% {
    height: 0px;
    border-width: 0px;
  }
  40% {
    height: 35px;
  }
  80%,
  100% {
    height: 70px;
    border-width: 5px;
  }
`;

export const wave = keyframes`
  from {
    transform: skew(0, -2deg);
  }
  to {
    transform: skew(0, 2deg);
  }
`;
