import React from 'react';
import styled from 'styled-components/macro';
import Beer from '../components/Beer';

const StyledPage = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: #9fb4d3;
`;

const HomePage = () => (
  <StyledPage>
    <Beer />
  </StyledPage>
);

export default HomePage;
