import React from 'react';
import styled from 'styled-components/macro';
import Bubble from './Bubble';
import Foam from './Foam';

import { fillup, wave } from './keyframes';

const StyledBeer = styled.div`
  position: absolute;
  bottom: 15px;
  margin: 0 5px;
  width: 60px;
  background-color: rgba(222, 124, 0);
  border-radius: 0 0 5px 5px;
  border-top: solid 0px rgba(222, 124, 0);
  overflow: hidden;

  animation: ${fillup} 5s ease infinite;

  &:after {
    position: absolute;
    display: block;
    content: ' ';
    width: 100%;
    background-color: white;
    border-radius: 5px 5px 0 0;

    animation: ${wave} 0.5s alternate infinite;
  }
`;

const Beer = () => {
  return (
    <>
      <StyledBeer>
        <Bubble left={6} duration={1000} delay={1000} />
        <Bubble left={12} duration={1100} delay={700} />
        <Bubble left={18} duration={1300} delay={1200} />
        <Bubble left={24} duration={700} delay={1100} />
        <Bubble left={32} duration={800} delay={1300} />
        <Bubble left={48} duration={1050} delay={900} />
      </StyledBeer>
      <Foam />
    </>
  );
};
export default Beer;
