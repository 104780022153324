import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyDsk7ChuUVbX249xFHGbNZpAc5qgl-AJe0',
  authDomain: 'brewstr.io',
  databaseURL: 'https://brewstr-pwa.firebaseio.com',
  projectId: 'brewstr-pwa',
};

const app = firebase.initializeApp(config);

export const googleOAuthProvider = new firebase.auth.GoogleAuthProvider();

export default app;
