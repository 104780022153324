import styled, { keyframes } from 'styled-components/macro';

const bubbleup = keyframes`
  0% {
    bottom: 0;
  }

  50% {
    background-color: rgba(255, 255, 255, 0.2);
    bottom: 50px;
  }

  100% {
    background-color: rgba(255, 255, 255, 0);
    bottom: 100px;
  }
}`;

const Bubble = styled.div`
  left: ${props => props.left}px;
  color: blueviolet;
  background-color: rgba(255, 255, 255, 0.2);
  bottom: 0;
  border-radius: 5px;
  height: 10px;
  width: 10px;
  position: absolute;

  animation: ${bubbleup} ${props => props.duration}ms linear ${props => props.delay}ms infinite;
`;

export default Bubble;
