import React from 'react';
import styled from 'styled-components/macro';
import { fillup, wave } from './keyframes';

const StyledFoam = styled.div`
  position: absolute;
  bottom: 10px;
  animation: ${fillup} 5s ease infinite, ${wave} 0.5s alternate infinite;
`;

const Foam = styled.div`
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  float: left;
  position: absolute;
  z-index: 999;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #fefefe;
`;

const FoamContainer = () => {
  const baseTop = -16;
  const random = (min, max) =>
    Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);

  return (
    <StyledFoam>
      <Foam top={baseTop + random(-3, 3)} left={2} />
      <Foam top={baseTop + random(-3, 3)} left={12} />
      <Foam top={baseTop + random(-3, 3)} left={22} />
      <Foam top={baseTop + random(-3, 3)} left={32} />
      <Foam top={baseTop + random(-3, 3)} left={42} />
      <Foam top={baseTop + random(-3, 3)} left={52} />
    </StyledFoam>
  );
};

export default FoamContainer;
