/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import firebase from '../firebase';

const AuthRoute = ({ component: Component, ...rest }) => {
  const auth = firebase.auth();
  const { currentUser } = auth;
  const [user, setUser] = useState(currentUser === null ? undefined : currentUser);
  auth.onAuthStateChanged(firebaseUser => setUser(firebaseUser));
  return (
    <Route
      {...rest}
      render={props => {
        if (user === undefined) {
          return <>Loading...</>;
        }
        const { location } = props;
        if (user === null) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default AuthRoute;
