import React from 'react';
import styled from 'styled-components/macro';
import Beer from './Beer';

const StyledGlass = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 90px;
  width: 70px;
  background-color: rgba(220, 220, 220, 0.3);
  border-radius: 5px;
`;

const Handle = styled.div`
  position: absolute;
  right: -20px;
  top: 20px;
`;
const Top = styled.div`
  height: 20px;
  width: 10px;
  border-top: solid 10px rgba(220, 220, 220, 0.4);
  border-right: solid 10px rgba(220, 220, 220, 0.4);
  border-top-right-radius: 20px;
`;
const Bottom = styled.div`
  height: 20px;
  width: 10px;
  border-bottom: solid 10px rgba(220, 220, 220, 0.4);
  border-right: solid 10px rgba(220, 220, 220, 0.4);
  border-bottom-right-radius: 20px;
`;

const Glass = () => {
  return (
    <StyledGlass>
      <Beer />
      <Handle>
        <Top />
        <Bottom />
      </Handle>
    </StyledGlass>
  );
};

export default Glass;
