import React from 'react';
import PropTypes from 'prop-types';
import firebase, { googleOAuthProvider } from '../firebase';
import LoginButton from '../components/LoginButton';

const LoginPage = ({ location, history }) => {
  const { state = {} } = location;
  const { from = '/' } = state;

  const login = async () => {
    try {
      await firebase.auth().signInWithPopup(googleOAuthProvider);
      history.push(from);
    } catch (err) {
      // TODO: Handle sign-in failures
    }
  };

  return <LoginButton onClick={login} />;
};

LoginPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default LoginPage;
